// ApplyForm.js
import React, { forwardRef } from 'react';
import { Widget } from '@typeform/embed-react';

const ApplyForm = forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <Widget id="MAB_Career_Link_1" style={{ width: '100%', height: '500px' }} />
    </div>
  );
});

export default ApplyForm;
