import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

const accordionData = [
  {
    id: 'panel01',
    title: 'TRANSPARENCY',
    content: 'We build relationships and operate in the most transparent manner, where accessibility to information is mirrored all the way.'
  },
  {
    id: 'panel02',
    title: 'CREDIBILITY',
    content: 'We are committed to stand by our promises and live up to them.'
  },
  {
    id: 'panel03',
    title: 'PERFORMANCE & SPEED',
    content: 'We operate with a sense of urgency and discipline. We are driven by goals, milestones and achievement.'
  },
  {
    id: 'panel04',
    title: 'QUALITY OF SELECTION - HIGH JOB FIT & STABILITY RATIO',
    content: 'It is not about count, it is about quality of people & employment environment and well-being.'
  }
];

export default function Values() {
    const { t } = useTranslation();
    const isRTL = i18n.language === 'ar';
  const [expandedPanel, setExpandedPanel] = React.useState(null);

  const handleExpandClick = (panel) => () => {
    setExpandedPanel(expandedPanel === panel ? null : panel);
  };

  return (
    <div style={{ direction: isRTL ? 'rtl' : 'ltr' }}>
    <Box  id='values' sx={{ width: '90%', margin: '4rem auto' }}>
      <Typography variant='h3' sx={{ fontFamily: isRTL?'Readex Pro': "Lato", fontSize: { xs: '30px', md: '40px' }, fontWeight: 'bold', color: '#454461', marginBottom: '5rem', marginTop: {xs:'8rem',md:'12rem' }}}>
        {t("Our Values")}
      </Typography>
      {accordionData.map((item) => (
        <Card 
          key={item.id} 
          sx={{ 
            maxWidth: 1200, 
            margin: '0 auto 2rem', 
            borderRadius: '15px', 
            background: 'linear-gradient(180deg, hsla(161, 100%, 20%, 1) 0%, hsla(161, 25%, 60%, 1) 0%, hsla(161, 25%, 80%, 1) 66%, hsla(160, 24%, 90%, 1) 86%, hsla(160, 25%, 95%, 1) 94%, hsla(0, 0%, 100%, 1) 100%)', 
            boxShadow: 3, 
            border: '0px solid #454461' 
          }}
        >
          <Accordion
            expanded={expandedPanel === item.id}
            onChange={handleExpandClick(item.id)}
            sx={{ backgroundColor: 'transparent' }}
          >
            <AccordionSummary
              expandIcon={<IconSwitcher expanded={expandedPanel === item.id} />}
              aria-controls={`${item.id}-content`}
              id={`${item.id}-header`}
              sx={{ flexDirection: 'row', alignItems: 'center' }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' ,padding:'0.5rem'}}>
                <Typography variant='h3' sx={{ fontFamily: isRTL?'Readex Pro': "Lato", fontWeight: 500, color: '#454461', fontSize: { xs: '30px', md: '40px' }, textAlign: 'left',marginRight:'1rem' }}>
                  •
                </Typography>
                <Typography sx={{ margin: { xs: '0rem', md: '1rem' }, fontFamily: isRTL?'Readex Pro': "Lato", fontWeight: 900, color: '#454461', fontSize: { xs: '15px', md: '20px' } }}>
                  {t(item.title)}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: 'transparent' }}>
              <Divider sx={{ backgroundColor: '#454461' }}></Divider>
              <Typography sx={{ margin: '2rem 2rem', fontFamily: isRTL?'Readex Pro': "Lato", color: '#454461', fontWeight: '600', fontSize: '18px' }}>
                {t(item.content)}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Card>
      ))}
    </Box></div>
  );
}

function IconSwitcher({ expanded }) {
  return (
    <Box>
      {expanded ? (
        <RemoveIcon sx={{ backgroundColor: '#F3F3F3', borderRadius: '50px', border: '1px solid black', color: 'black', fontSize: '30px', marginRight: '1rem' }} />
      ) : (
        <AddIcon sx={{ backgroundColor: '#F3F3F3', borderRadius: '50px', border: '1px solid black', color: 'black', fontSize: '30px', marginRight: '1rem' }} />
      )}
    </Box>
    
  );
}
