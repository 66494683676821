import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import LanguageIcon from '@mui/icons-material/Language';
import logo from '../Assets/logo.jpg';
import { Scroll } from '../../Scroll';
import { HashLink as Link } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';

const pages = [
  { label: 'About', path: '#about' },
  { label: 'Methodology', path: '#method' },
  { label: 'Services', path: '#services' },
  { label: 'Values', path: '#values' },
  { label: 'Contact', path: '#contact' },
];

function Navbar() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [showNavbar, setShowNavbar] = React.useState(true);
  const [lastScrollY, setLastScrollY] = React.useState(0);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const controlNavbar = () => {
    if (typeof window !== 'undefined') {
      if (window.scrollY > lastScrollY) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }
      setLastScrollY(window.scrollY);
    }
  };

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div style={{ direction: isRTL ? 'rtl' : 'ltr' }}>
      <Box sx={{ flexGrow: 1, position: 'relative', zIndex: 10 }}>
        <Grid container sx={{ justifyContent: "center", alignItems: 'center', textAlign: 'center', margin: 'auto auto' }}>
          <Grid item xs={12} md={8} lg={6}>
            <AppBar
              position="fixed"
              sx={{
                borderRadius: { xs: '0rem', md: '10px' },
                marginTop: { xs: '0rem', md: '2rem' },
                backgroundColor: '#fbfbfb',
                zIndex: 10,
                width: { xs: '100%', md: '90%', lg: '70%' },
                marginRight: { xs: '0', md: '5%', lg: '15rem' },
                transform: showNavbar ? 'translateY(0)' : 'translateY(-150%)',
                transition: 'transform 0.3s ease-in-out',
                display: { xs: 'none', md: 'block' },
                '@media (min-width: 1200px) and (max-width: 1450px)': {
                  width: '95%', // Adjust width for responsiveness
                  marginRight: '2rem', // Adjust margin for better fit
                },
              }}
            >
              <Container maxWidth="xl">
                <Toolbar disableGutters>
                  <Box sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1 }}>
                    <IconButton
                      sx={{ display: { xs: 'flex', md: 'none' } }}
                      size="large"
                      aria-label="open drawer"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleDrawerToggle}
                      color="black"
                    >
                      <MenuIcon />
                    </IconButton>
                  </Box>
                  <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1, alignItems: 'center', marginLeft: isRTL ? '-2rem' : '1rem', marginRight: isRTL ? '3rem' : '0rem' }}>
                    <img src={logo} style={{ width: '150px', height: '90px' }} alt="Logo" />
                    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', overflowX: 'auto' }}>
                      {pages.map((page) => (
                        <Button
                          key={page.label}
                          component={Link}
                          to={page.path}
                          scroll={el => Scroll(el)}
                          smooth
                          onClick={handleDrawerToggle}
                          sx={{
                            my: 1,
                            color: '#454461',
                            display: 'block',
                            marginLeft: { xs: '0.5rem', md: '1rem', lg: '3rem' },
                            fontWeight: '600',
                            fontFamily: isRTL ? 'Readex Pro' : "Lato",
                            borderRadius: '10px',
                            color: page.label === 'Contact' ? 'white' : '#454461',
                            backgroundColor: page.label === 'Contact' ? '#80B3A3' : 'transparent',
                            border: page.label === 'Contact' ? '1px solid #80B3A3' : 'none',
                            '&:hover': {
                              backgroundColor: page.label === 'Contact' ? 'transparent' : '#80B3A3',
                              color: page.label === 'Contact' ? '#80B3A3' : 'white',
                              border: page.label === 'Contact' ? '1px solid #80B3A3' : 'none',
                            },
                          }}
                        >
                          {t(page.label)}
                        </Button>
                      ))}
                    </Box>
                    {/* Language Button inside the Navbar */}
                    
                    <Button
                      size="large"
                      onClick={() => handleLanguageChange(isRTL ? 'en' : 'ar')}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: '#454461',
                        ml: 6,
                    fontFamily:'lato',
                    border:'1px solid #80B3A3',
                    borderRadius:'10px',
                    marginLeft:'4.5rem',
                    '&:hover': {
                              backgroundColor:'#80B3A3',
                              border:'1px solid #80B3A3',
                               color:'white'
                      }}}
                    >
                      
                      {isRTL ? 'EN' : 'AR'}
                    </Button>
                  </Box>
                </Toolbar>
              </Container>
            </AppBar>

            {/* Mobile Menu Icon Button */}
            <Box
              sx={{
                display: { xs: 'flex', md: 'none' },
                position: 'static',
                top: '1rem',
                left: '1rem',
                marginTop: '1rem',
                marginLeft: '1rem',
                zIndex: 20
              }}
            >
              <IconButton
                size="large"
                aria-label="open drawer"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleDrawerToggle}
                color="black"
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>

        {/* Drawer for mobile menu */}
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          sx={{ display: { xs: 'block', md: 'none' } }}
        >
          <Box
            sx={{
              width: 250,
              backgroundColor: '#F8FBFA',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            role="presentation"
            onClick={handleDrawerToggle}
            onKeyDown={handleDrawerToggle}
          >
            {/* Logo inside the drawer */}
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
              <img src={logo} style={{ width: '130px', height: '90px' }} alt="Logo" />
            </Box>
            <List sx={{ width: '100%' }}>
              {pages.map((page) => (
                <ListItem
                  button
                  key={page.label}
                  component={Link}
                  to={page.path}
                  scroll={el => Scroll(el)}
                  smooth
                  sx={{
                    justifyContent: 'center',
                    fontFamily: isRTL ? 'Readex Pro' : "Lato",
                    color: '#454461',
                    '& .MuiListItemText-root': {
                      textAlign: 'center',
                      fontFamily: isRTL ? 'Readex Pro' : "Lato",
                    },
                  }}
                >
                  <ListItemText primary={t(page.label)} />
                </ListItem>
              ))}
              {/* Language Button in Drawer */}
              <ListItem
                button
                sx={{
                  justifyContent: 'center',
                  fontFamily: isRTL ? 'Readex Pro' : "Lato",
                  color: '#006747',
                  '& .MuiListItemText-root': {
                    textAlign: 'center',
                    '&:hover': {
                        backgroundColor:'#80B3A3',
                        border:'1px solid #80B3A3',
                        color:'white'
                    }
                  },
                }}
              >

                <Button
                  size="large"
                  onClick={() => handleLanguageChange(isRTL ? 'en' : 'ar')}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#454461',
                    ml: 6,
                    fontFamily:'lato',
                    border:'1px solid #80B3A3',
                    borderRadius:'10px',
                    marginLeft:'4.5rem'
                  }}
                >
                 
                  {isRTL ? 'EN' : 'AR'}
                </Button>
                <ListItemText primary="" />
              </ListItem>
            </List>
          </Box>
        </Drawer>
      </Box>
    </div>
  );
}

export default Navbar;
