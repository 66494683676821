import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button, Typography, Card, Divider, Paper } from '@mui/material';
import Navbar from '../Nabvbar/Navbar';
import EastIcon from '@mui/icons-material/East';
import CountUp from 'react-countup';
import one from '../Assets/himg.png';
import q1 from '../Assets/01.png';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Header() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isRTL = i18n.language === 'ar';
  const handleApplyClick = () => {
    window.open('https://recruitcrm.io/talent_pool_submission/MAB_Career_Link_3', '_blank', 'noopener,noreferrer');
  };

  return (
    <Box id='header' sx={{ position: 'relative', height: '100%', backgroundImage: `url(${q1})`, backgroundSize: 'cover', zIndex: '1', fontFamily: "Lato" }}>
      <Navbar />
      <Grid container spacing={2} columns={16} sx={{ height: '100%' }}>
        <Grid item xs={12} md={7} sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: { xs: 'center', md: 'center' }, padding: { xs: '0rem', md: '0' }, order: { xs: 2, md: 1 }, marginLeft: { xs: '3.5rem', md: '5rem' }, backgroundColor: '' ,marginTop:{xs:'-1rem',md:'0rem'}}}>
          <Typography sx={{ textAlign: { xs: 'center', md: 'center' }, fontFamily: isRTL?'Readex Pro': "Lato", fontWeight: '700', color: '#454461', fontSize: { xs: '36px', md: '50px' }, marginTop: { xs: '2rem', md: '14rem' }, marginLeft: { xs: '0rem', sm: '8rem' } }} variant='h2'>
            {t('Quality Recruitment')}
          </Typography>
          <Typography sx={{ textAlign: { xs: 'center', md: 'center' }, fontFamily: isRTL?'Readex Pro': "Lato", fontWeight: '700', fontSize: { xs: '36px', md: '50px' }, color: '#454461', lineHeight: '2', marginTop: { xs: '0rem', md: '0' }, marginLeft: { xs: '0rem', sm: '8rem' } }} variant='h2'>
            {t('Solutions')}
          </Typography>

          <Typography sx={{ textAlign: 'center', fontFamily: isRTL?'Readex Pro': "Lato", fontWeight: '400', fontSize: { xs: '24px', md: '35px' }, color: '#454461', lineHeight: '3', marginTop: { xs: '1rem', md: '2rem' }, marginLeft: { xs: '0rem', sm: '8rem' }, }} variant='h2'>
            {t('Job Applicant')}
          </Typography>
          <Button 
            className='contact-button' 
            sx={{ 
                textAlign:'center',
                justifyContent:'center',
                alignItems:'center',
              backgroundColor: '#EA9535', 
              borderRadius: '10px', 
              color: 'white', 
              fontSize: { xs: '16px', md: '20px' }, 
              padding: { xs: '0.6rem 2rem', md: '0.6rem 3rem' }, 
              fontWeight: 'bold', 
            fontFamily: isRTL?'Readex Pro': "Lato",
              marginTop: { xs: '-0.5rem', md: '0rem' }, 
              marginLeft: { xs: '0rem', sm: '8rem' }, 
              border: '1px solid #EA9535', 
              '&:hover': { 
                backgroundColor: 'transparent', 
                color: '#454461', 
                border: '1px solid #454461' 
              }, 
            }}
            onClick={handleApplyClick}
          >
            {t('Apply Here')} <EastIcon sx={{ paddingLeft: '0.5rem' }} />
          </Button>
        </Grid>
        <Grid item xs={12} md={7} sx={{ display: 'flex', justifyContent: 'left', alignItems: 'left', position: 'relative', marginTop: { xs: '2rem', md: '9rem' }, order: { xs: 1, md: 2 },backgroundColor:'' }}>
          <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'left', backgroundColor:'',margin:'0rem 0rem',paddingRight:'4rem 2rem'}}>
            <img className='img'
              src={one}
              style={{
                width: { xs: '30%', md: '60%' },
                maxHeight: '460px',
                marginRight: { xs: '0rem', md: '1rem' },
                position: 'relative',
                zIndex: '-1'
              }}
              alt="Background"
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ justifyContent: 'center', textAlign: 'center', alignItems: 'center', position: 'relative', top: { xs: '4rem', md: '4rem' } }}>
        <Card className='card' sx={{
          maxWidth: { xs: 600, md: 1000 },
          margin: { xs: '1rem 2rem', md: 'auto auto', sm: '0rem 5rem' },
          borderRadius: '20px',
          backgroundColor: 'fff2ec',
          padding: { xs: '0.5rem', md: '2rem' },
          zIndex: '20',
          boxShadow: '0px 4px 10px rgba(250, 148, 97, 0.3);'
        }}>
           <div style={{ direction: isRTL ? 'rtl' : 'ltr' }}>
          <Grid container spacing={1} columns={12}>
            <Grid item xs={4}>
              <Typography sx={{ fontFamily: isRTL?'Readex Pro': "Lato", margin: '1rem', fontWeight: 'bold',color:'f89767' }} variant='h5'>
                <CountUp start={0} end={2} duration={3} />
              </Typography>
              <Typography sx={{ fontFamily: isRTL?'Readex Pro': "Lato", fontSize: { xs: '12px', md: '16px' },color:'f89767'}}>{t('Time In Services (years)')}</Typography>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={4}>
              <Typography sx={{fontFamily: isRTL?'Readex Pro': "Lato", margin: '1rem', fontWeight: 'bold',color:'f89767' }} variant='h5'>
                <CountUp start={0} end={460} duration={3} />
              </Typography>
              <Typography sx={{ fontFamily: isRTL?'Readex Pro': "Lato", fontSize: { xs: '11px', md: '16px' },color:'f89767'}}>{t('Applicants Hired')}</Typography>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={3}>
              <Typography sx={{ fontFamily: isRTL?'Readex Pro': "Lato", marginTop: '1rem', marginLeft: '1rem', marginBottom: '1rem', fontWeight: 'bold' ,color:'#454461'}} variant='h5'>
                <CountUp start={0} end={130} duration={3} />
              </Typography>
              <Typography sx={{ fontFamily: isRTL?'Readex Pro': "Lato", fontSize: { xs: '12px', md: '16px' },color:'#454461'}}>{t('Applicants Being Processed')}</Typography>
            </Grid>
          </Grid>
          </div>
        </Card>
      </Box>
    </Box>
  );
}
