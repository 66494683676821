import logo from './logo.svg';
import './App.css';
import Navbar from './Components/Nabvbar/Navbar';
import Header from './Components/Header/Header';
import About from './Components/About/About';
import Methodology from './Components/Methodology/Methodology';
import Services from './Components/Services/Services';
import Values from './Components/Values/Values';
import Footer from './Components/Footer/Footer';

import { BrowserRouter, Routes } from 'react-router-dom';
import { Route } from 'react-router-dom';
import ApplyForm from './Components/Header/ApplyForm';
import Services1 from './Components/Services/Services1';
import './i18n';
import { useTranslation } from 'react-i18next';

function App() {
  const { i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';
  return (
   /*  <div dir={isRTL ? 'rtl' : 'ltr'}> */
    <div  className="App">
    {/*   <Navbar></Navbar> */}
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<><Header /><About /><Methodology />{/* <Services /> */}<Services1></Services1><Values /><Footer /></>} />
        <Route path="/apply" element={<ApplyForm />} />
        {/* Add additional routes if necessary */}
      </Routes>
    </BrowserRouter>
    </div>/* </div> */
  );
}

export default App;
