import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import six from '../Assets/22.png';
import seven from '../Assets/33.png';
import eight from '../Assets/44.png';
import nine from '../Assets/55.png';
import ten from '../Assets/66.png';
import eleven from '../Assets/99.png';
import MiscellaneousServicesRoundedIcon from '@mui/icons-material/MiscellaneousServicesRounded';
import CardMembershipRoundedIcon from '@mui/icons-material/CardMembershipRounded';
import { Avatar } from '@mui/material';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import WorkspacePremiumRoundedIcon from '@mui/icons-material/WorkspacePremiumRounded';
import Groups2RoundedIcon from '@mui/icons-material/Groups2Rounded';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily:'lato'
}));

const cardsData = [
  { details: 'Select from database, Make Interviews Online, Screen and Select your candidates and monitor the entire processing stages till the candidate arrives to your airport.', title: 'Online Services', img: six,m:'1rem' ,bg:'#006747ff' },
  { details: 'Ongoing pipeline of Qualified shortlist of validated candidates at various professions available online for your Access.', title: 'Strong Database', img:seven ,m:'1rem',bg:'#006747ff' },
  { details: 'Seeking certification and adoption of the highly cleaning & janitorial standards, we can ensure the deep training on the finest cleaning & housekeeping standards.', title: 'BICSc Janitorial Training', img: eight,m:'1rem',bg:'#006747ff'   },
  { details: 'With our High Job Fit Ratio focus, we still offer 3 months guarantee of replacement to the employee.', title: 'Guarantee', img: nine ,m:'1rem',bg:'#006747ff' },
  { details: 'Dedicated Customer Service Agents to answer customers inquiries. Constant & Immediate Updates on candidates and processing status.', title: 'Customer Service', img: ten,m:'1rem',bg:'#006747ff'  },
  { details: 'We use psychometric assessments to identify individual potentials and skills, allowing us to assemble a team that perfectly fits your work requirements.', title: 'Selecting Technical Team on your Behalf', img: eleven,m:'1rem' ,bg:'#006747ff'},
];

export default function Services1() {
    const { t } = useTranslation();
    const isRTL = i18n.language === 'ar';
  return (
   /*  <div dir={isRTL ? 'rtl' : 'ltr'}> */
    <Box id='services' sx={{ flexGrow: 1 ,marginLeft:{xs:'1rem',md:'0rem'},marginRight: '4rem'}}>
        <Typography 
  variant='h3' 
  sx={{
    fontFamily: 'Raleway',
    fontSize: { xs: '30px', md: '40px' },
    fontWeight: 'bold',
    marginBottom: '5rem',
    marginTop: {xs:'8rem',md:'12rem'},
    marginLeft:{xs:'2rem',md:'0rem'}
  }}
>
  <span style={{ color: '#006747ff' ,fontFamily: isRTL?'Readex Pro': "Lato"}}>{t("Services")}</span> 
  <span style={{ color: 'grey',fontFamily: isRTL?'Readex Pro': "Lato" }}> {t("We Offer")}</span>
</Typography>

      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 6, md: 12 }}>
        {cardsData.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
        
            <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box sx={{ display: 'flex', alignItems: 'right', justifyContent: 'right',backgroundColor:'',textAlign:'right',marginTop:item.m }}>
                
              <img src={item.img} alt={item.title} style={{ maxHeight: '90%', maxWidth: '70%' }} />
              </Box>
              </Grid>
              <Grid item xs={8}>
              <div style={{ direction: isRTL ? 'rtl' : 'ltr' }}>
              <Typography variant="h6" sx={{ marginTop: 1,textAlign:'left',color:'#454461', fontWeight:'bold',marginRight:'0rem', fontFamily: isRTL?'Readex Pro': "Lato"}}>{t(item.title)}</Typography></div>
              <div style={{ direction: isRTL ? 'rtl' : 'ltr' }}>
              <Typography sx={{ marginTop: 0.5,textAlign:'left',color:'#454461',marginRight:'0rem' ,marginBottom:{sm:'3rem',xs:'2.5rem',md:'0rem'},fontFamily: isRTL?'Readex Pro': "Lato"}}>{t(item.details)}</Typography></div>
              </Grid>
              </Grid>
           
            
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
