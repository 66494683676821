import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import two from '../Assets/c.png';
import three from '../Assets/ccc.png';
import four from '../Assets/cc.png';
import five from '../Assets/ccccc.png';
import { Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'lato'
}));

const cardsData = [
  { details: 'Fully integrative CRM system to govern the interactive relation between employers and candidates, provide online interviews, selection, and status updates on applicants` processing. It helps match, track, and inform you of the status of your selection and timelines efficiently, ensuring seamless communication and comprehensive management.' 
                                                                                      , title: 'Technology', img: two, m: '1rem', mb: '1rem' },
  { details: 'Recruitment Consultants, with best recruitment and selection practices & psychometric assessments securing Qualified shortlist of validated candidates. Enabling you to fill your management & specialist positions in areas including - but not limited to - Healthcare, Facility Management, Food & Beverages, Hospitality, IT, Domestic Workers.', title: 'Recruitment Consultants', img: three, m: '1rem', mb: '2rem' },
  { details: 'With an extensive track record of compliance, discipline and great relationship we ensure speed tracking our processing cycle.', title: 'Public & Govt Relation Agents', img: four, m: '3rem', mb: '2rem' },
  { details: 'Dedicated Customer Service Agents to answer customers inquiries. Constant & Immediate Updates on candidates and processing status.', title: 'Customer Service', img: five, m: '3rem', mb: '2rem' },
];

export default function Methodology() {
    const { t } = useTranslation();
    const isRTL = i18n.language === 'ar';
  return (
    <div dir={isRTL ? 'rtl' : 'ltr'}>
    <Box id='method' sx={{ flexGrow: 1, margin: '1rem 2rem' }}>
         <Typography variant='h3'sx={{fontFamily: isRTL?'Readex Pro': "Lato",fontSize:{xs:'30px',md:'40px'},fontWeight:'bold',color:'#006747ff',marginBottom:'5rem',marginTop:{xs:'3rem',md:'6rem'}}}>{t("Our Methodology")}</Typography>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 6, md: 12 }}>
        {cardsData.map((item, index) => (
          <Grid item xs={12} sm={6} md={6} key={index}>
            <Card sx={{ maxWidth: 1000, height:{xs:'auto',sm:'auto', md:'300px'},borderRadius: '10px', backgroundColor: '#F3F3F3', boxShadow: 3, border: '0px solid #454461', alignItems: 'center', justifyContent: 'center', textAlign: 'center', margin: 'auto auto' }}>
              <Grid container spacing={2} sx={{ padding: '2rem' }}>
                <Grid item xs={12} sm={4}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '200px', width: '100%', backgroundColor: '' }}>
                    <img src={item.img} style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover', marginTop: item.m }} />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography variant="h6" sx={{ marginTop: item.m, textAlign: 'center', color: '#454461', textDecoration: 'underline', textDecorationColor: '#80B3A3', fontWeight: 'bold', marginRight: '1rem' ,fontFamily: isRTL?'Readex Pro': "Lato"
                  }}>
                  {t(item.title)}
                  </Typography>
                  <div style={{ direction: isRTL ? 'rtl' : 'ltr' }}>
                  <Typography sx={{ marginTop: 0.5, textAlign: 'center', color: '#454461', marginRight: '0rem', fontFamily: isRTL?'Readex Pro': "Lato",marginBottom: item.mb }}>
                    {t(item.details)}
                  </Typography></div>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box></div>
  );
}
