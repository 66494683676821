import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import { BadgeOutlined, TrendingUp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

export default function About() {
  const { t } = useTranslation();
  const isRTL = i18n.language === 'ar';

  return (
    <div id="about" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '90%', margin: 'auto' }}>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: '90%', maxWidth: '1500px', mt: '15rem', mb: '6rem', gap: '0.5rem' }}>
        <Card sx={{
          position: 'relative',
          overflow: 'hidden',
          p: '3rem',
          height: '250px',
          width: { xs: '100%', md: '50%' },
          boxSizing: 'border-box',
          transition: 'background-color 0.3s ease',
          backgroundColor: '#F0F6F4',
          borderTopRightRadius: { xs: '50px', md: '0' },
          borderBottomRightRadius: { xs: '0', md: '0' },
          borderTopLeftRadius: { xs: '50px', md: '50px' },
          borderBottomLeftRadius: { xs: '0', md: '50px' },
          '&:hover': {
            backgroundColor: '#80B3A3',
            '& .about-description': {
              transform: 'translateY(0)',
            },
          }
        }}>
          <div style={{ direction: isRTL ? 'rtl' : 'ltr' }}>
            <Typography sx={{ fontFamily: isRTL ? 'Readex Pro' : 'Lato', fontSize: { xs: '30px', md: '40px' }, fontWeight: 'bold', color: '#454461', mb: '1rem', mt: '2rem' }} variant="h3">{t("About Us")}</Typography>
          </div>
          <BadgeOutlined sx={{ fontSize: '50px', color: '#006747' }} />
          <Box className="about-description" sx={{
            direction: isRTL ? 'rtl' : 'ltr',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#80B3A3',
            p: '1rem',
            height: '100%',
            transform: 'translateY(100%)',
            transition: 'transform 0.3s ease-in-out',
            color: 'white',
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}>
            <Typography sx={{ fontFamily: isRTL ? 'Readex Pro' : 'Lato', fontSize: { xs: '15px', md: '20px' }, fontWeight: '700', color: '#ffffff' }}>
              {t("We are an agency that was founded in response to the increasing & transformational workforce demand in securing the best candidates while ensuring decent, promising, and responsible employment opportunities for the candidates.")}
            </Typography>
          </Box>
        </Card>

        <Card sx={{
          position: 'relative',
          overflow: 'hidden',
          p: '3rem',
          height: '250px',
          width: { xs: '100%', md: '50%' },
          boxSizing: 'border-box',
          transition: 'background-color 0.3s ease',
          backgroundColor: '#C8DED7',
          borderTopLeftRadius: { xs: '0', md: '0' },
          borderBottomLeftRadius: { xs: '50px', md: '0' },
          borderTopRightRadius: { xs: '0', md: '50px' },
          borderBottomRightRadius: { xs: '50px', md: '50px' },
          '&:hover': {
            backgroundColor: '#80B3A3',
            '& .about-description': {
              transform: 'translateY(0)',
            },
          }
        }}>
          <div style={{ direction: isRTL ? 'rtl' : 'ltr' }}>
            <Typography sx={{ fontFamily: isRTL ? 'Readex Pro' : 'Lato', fontSize: { xs: '30px', md: '40px' }, fontWeight: 'bold', color: '#454461', mb: '1rem', mt: '2rem' }} variant="h3">{t("Why Us?")}</Typography>
          </div>
          <TrendingUp sx={{ fontSize: '50px', color: '#006747' }} />
          <Box className="about-description" sx={{
            direction: isRTL ? 'rtl' : 'ltr',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#80B3A3',
            p: '1rem',
            height: '100%',
            transform: 'translateY(100%)',
            transition: 'transform 0.3s ease-in-out',
            color: 'white',
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}>
            <Typography sx={{ fontFamily: isRTL ? 'Readex Pro' : 'Lato', fontSize: { xs: '15px', md: '20px' }, fontWeight: '700', color: '#ffffff' }}>
              {t("We meticulously screen employers just as we screen candidates. This thorough approach ensures higher success rates in employment engagements, leading to better matches and greater satisfaction for both parties.")}
            </Typography>
          </Box>
        </Card>
      </Box>
    </div>
  );
}
